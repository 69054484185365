/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import withHeader from '../../HOC/WithHeader';
import ProfileSubHeader from './components/header';
import ProfileInfoHeader from './components/ProfileInfoHeader';
import { Box, Tab, Tabs, css } from '@mui/material';
import ProfileInfoBody from './components/profileInfoBody';
import SettingsBody from './components/settingsBody';
import WABABody from './components/WABABody';
import { getProflieAPI } from './requests';
import { ProflieAPIResponseType } from './types';
import { orgProfileAtom } from './states';
import useQuery from '../../hooks/useQuery';
import Loader from '../../components/loader';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  const renderTabBody = () => {
    if (index === 0) {
      return <ProfileInfoBody />;
    }
    if (index === 1) {
      return <WABABody />;
    }
    if (index === 2) {
      return <SettingsBody />;
    }
    return 'todo';
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      css={css`
        margin-top: 20px;
      `}
    >
      {renderTabBody()}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const tabs = [
  { label: 'Profile', index: 0 },
  { label: 'WABA', index: 1 },
  { label: 'Settings', index: 2 },
];

const OrgProfile = () => {
  const [searchParam] = useSearchParams();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const orgId = searchParam.get('orgId') ?? '';
  const getSetProfileData = useCallback(() => {
    return getProflieAPI({ orgId });
  }, [orgId]);

  const {
    requestData: { loading },
  } = useQuery<ProflieAPIResponseType>({
    requestAtom: orgProfileAtom,
    queryFunction: getSetProfileData,
    cacheData: false,
  });

  if (loading) {
    return <Loader size={32} secondary={'Loading...'} />;
  }

  return (
    <div>
      <ProfileSubHeader />
      <Box
        css={css`
          margin: 48px 80px;
        `}
      >
        <ProfileInfoHeader />
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{ style: { backgroundColor: '#2196F3' } }}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.index}
                label={tab.label}
                {...a11yProps(tab.index)}
                sx={{ '&.Mui-selected': { color: '#2196F3' } }}
              />
            ))}
          </Tabs>
        </Box>
        {tabs.map((tab) => (
          <CustomTabPanel key={tab.index} value={value} index={tab.index}>
            {tab.label}
          </CustomTabPanel>
        ))}
      </Box>
    </div>
  );
};

export default withHeader(OrgProfile);
