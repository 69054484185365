import React from 'react';
import { Chip } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export enum StatusBadgeTypes {
  Active = 'Active',
  Deleted = 'Deleted',
  Paused = 'Paused',
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  PENDING = 'PENDING',
}

interface StatusBadgeProps {
  type: StatusBadgeTypes;
}

const statusStyles = {
  Active: {
    backgroundColor: 'rgba(0, 122, 90, 0.12)',
    color: '#007A5A',
    dotColor: '#007A5A',
  },
  Deleted: {
    backgroundColor: 'rgba(211, 47, 47, 0.30)',
    color: '#D32F2F',
    dotColor: '#D32F2F',
  },
  Paused: {
    backgroundColor: 'rgba(122, 122, 122, 0.12)',
    color: '#7a7a7a',
    dotColor: '#7a7a7a',
  },
  CREDIT: {
    backgroundColor: 'rgba(0, 122, 90, 0.12)',
    color: '#007A5A',
    dotColor: '#007A5A',
  },
  DEBIT: {
    backgroundColor: 'rgba(211, 47, 47, 0.30)',
    color: '#D32F2F',
    dotColor: '#D32F2F',
  },
  SUCCESS: {
    backgroundColor: 'rgba(0, 122, 90, 0.12)',
    color: '#007A5A',
    dotColor: '#007A5A',
  },
  FAILURE: {
    backgroundColor: 'rgba(211, 47, 47, 0.30)',
    color: '#D32F2F',
    dotColor: '#D32F2F',
  },
  PENDING: {
    backgroundColor: 'rgba(255, 152, 0, 0.12)',
    color: '#FF9800',
    dotColor: '#FF9800',
  },
};

const MapLabel: Record<string, string> = {
  ACTIVE: 'Active',
  IN_PROGRESS: ' In progress',
  INACTIVE: 'Inactive',
};

const StatusBadge: React.FC<StatusBadgeProps> = ({ type }) => {
  const { backgroundColor, color, dotColor } =
    statusStyles[type] || statusStyles.PENDING;
  const label = type.charAt(0).toUpperCase() + type.slice(1);
  debugger;
  return (
    <Chip
      icon={
        <FiberManualRecordIcon
          style={{ color: dotColor, height: '8px', width: '8px' }}
        />
      }
      label={MapLabel[label] || label}
      style={{
        backgroundColor,
        color,
        fontSize: '12px',
        fontWeight: '500',
        height: '24px',
        boxShadow: '0px 1px rgba(0, 0, 0, 0.12)',
      }}
    />
  );
};

export default StatusBadge;
