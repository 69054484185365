/** @jsxImportSource @emotion/react */
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  css,
} from '@mui/material';
import ProfileCell from '../../organisations/components/ProfileCell';
import StatusBadge from '../../../components/badge';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import { PaymentHistoryRowType } from '../types';
import { getPaymentHistory } from '../request';
import useQuery from '../../../hooks/useQuery';
import { paymentHistoryAtom, walletConfigAtom } from '../state';
import { getDescriptionFromAction } from '../../../utils';
import Loader from '../../../components/loader';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';

const PaymentHistory = () => {
  const walletConfig = useAtomValue(walletConfigAtom);

  const getter = useCallback(async () => {
    if (walletConfig.data?.balance) {
    }

    return await getPaymentHistory();
  }, [walletConfig.data?.balance]);

  const {
    requestData: { loading, error, data },
  } = useQuery<PaymentHistoryRowType[]>({
    requestAtom: paymentHistoryAtom,
    queryFunction: getter,
    cacheData: false,
  });

  if (error) {
    return null;
  }
  const renderTableRows = () => {
    if (loading) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={7}>
              <Box
                css={css`
                  height: 300px;
                  width: 100%;
                `}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={'8px'}
              >
                <Loader />
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
    if (error) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={7}>
              <Box
                css={css`
                  height: 300px;
                  margin: auto;
                `}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={'8px'}
              >
                <Typography
                  css={css`
                    color: rgba(0, 0, 0, 0.87);
                    font-size: 14px;
                    font-weight: 400;
                  `}
                >
                  Some error occured. Please try again later
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
    if (Array.isArray(data) && data.length === 0) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={7}>
              <Box
                css={css`
                  height: 300px;
                  margin: auto;
                `}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={'8px'}
              >
                <Typography
                  css={css`
                    color: rgba(0, 0, 0, 0.87);
                    font-size: 14px;
                    font-weight: 400;
                  `}
                >
                  No transactions made till now
                </Typography>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
    return (
      <TableBody>
        {(data ?? []).map((row, index: number) => (
          <TableRow
            key={`${row.name}-${index}`}
            onClick={() => {}}
            sx={{
              cursor: 'pointer',
              '&:last-child td, &:last-child th': { border: 0 },
            }}
          >
            <TableCell component="th" scope="row">
              <ProfileCell
                maxLetters={20}
                name={row.name}
                isInitialsAvatar={true}
              />
            </TableCell>
            <TableCell>
              <Typography>{getDescriptionFromAction(row.action)}</Typography>
            </TableCell>
            <TableCell>
              <StatusBadge type={row.transactionType} />
            </TableCell>
            <TableCell>{row.amount}</TableCell>
            <TableCell>{new Date(row.dateCreated).toLocaleString()}</TableCell>
            <TableCell>
              <StatusBadge type={row.status} />
            </TableCell>
            <TableCell>{/* <MoreVertIcon /> */}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  };
  return (
    <Box my={'24px'}>
      <Typography
        css={{
          color: '#2d2d2d',
          fontWeight: 600,
          fontSize: 18,
          marginBottom: '16px',
        }}
      >
        Payment History
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 2,
          maxHeight: 440,
        }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Org name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Initiated on</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {renderTableRows()}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PaymentHistory;
