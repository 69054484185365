/** @jsxImportSource @emotion/react */
import { FormEvent, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { css, TextField } from '@mui/material';
import { rechargeWallet } from '../request';
import { AddCreditModaLProps } from '../types';
import useRazorPayPaymentHook from '../../../hooks/useRazorPayPaymentHook';
import useStripePaymentHook from '../../../hooks/useStripePaymentHook';
import CustomButton from '../../../components/button/CustomButton';
import { formatCurrency } from '../../../utils/currencyFormatter';
import { walletConfigAtom } from '../state';
import { useAtomValue } from 'jotai';
import getGST from '../../../utils/currency';
import InfoIcon from '@mui/icons-material/Info';

const AddCreditModal = ({ open, handleClose, style }: AddCreditModaLProps) => {
  const [amount, setAmount] = useState('');
  const walletConfig = useAtomValue(walletConfigAtom);

  const [waitingOnStripePayment, setWaitingOnStripePayment] = useState(false);
  const { makeRazPayment } = useRazorPayPaymentHook();
  const { makeStripePayment } = useStripePaymentHook();
  const GST_Value = getGST(parseFloat(amount || '0'));

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const integerAmount = parseFloat(amount) + GST_Value;
    const rechargeWalletResponse = await rechargeWallet({
      amount: integerAmount,
    });

    if (rechargeWalletResponse?.paymentService === 'RAZORPAY') {
      const { orderId, apiKey } = rechargeWalletResponse.paymentData;
      makeRazPayment({ apiKey, orderId, amount: integerAmount });
    } else {
      setWaitingOnStripePayment(true);
      makeStripePayment({
        currentBalance: walletConfig?.data?.balance || 0,
        amount: integerAmount + '',
        successCallback: () => handleClose(),
        errorCallback: () => setWaitingOnStripePayment(false),
      });
    }
  };

  const renderGST = () => {
    if (walletConfig?.data?.currencyCode === 'INR') {
      return (
        <Box
          display="flex"
          flexDirection={'column'}
          justifyContent="space-between"
          alignItems="flex-start"
          mt={'16px'}
          mb={'16px'}
        >
          <Typography fontSize={'1rem'} fontWeight={600}>
            GST 18%
          </Typography>
          <Typography fontSize={'1rem'}>
            {formatCurrency(GST_Value, walletConfig?.data?.currencyCode)}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableRestoreFocus
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 350,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 1,
          ...style,
        }}
      >
        <Typography
          fontWeight={600}
          py={1}
          px={2}
          borderBottom={'1px solid #e0e0e0'}
        >
          Wallet Recharge
        </Typography>
        <Box component="form" onSubmit={handleSubmit} p={2}>
          <Typography variant="h6" fontWeight={600} fontSize={'1rem'} mb={1}>
            Amount
          </Typography>
          <TextField
            required
            autoFocus
            value={amount}
            onChange={(e) => {
              if (!isNaN(Number(e.target.value))) {
                setAmount(e.target.value);
              }
            }}
            variant="outlined"
            sx={{
              width: '100%',
              borderRadius: 2,
            }}
            InputProps={{
              style: {
                width: '100%',
                borderRadius: 2,
              },
            }}
          />
          {renderGST()}
          <Typography
            variant={'caption'}
            css={css`
              display: flex;
              align-items: center;
              color: #555;
              margin-top: 10px;
              column-gap: 4px;
            `}
          >
            <InfoIcon
              css={css`
                color: #000;
                height: 12px;
                width: 12px;
              `}
            />
            Wallet recharge is non-refundable
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={1}
          >
            <Typography fontWeight={600}>Total</Typography>
            <Typography fontWeight={600}>
              {formatCurrency(
                parseFloat(amount || '0') + GST_Value,
                walletConfig?.data?.currencyCode
              )}
            </Typography>
          </Box>
          <CustomButton
            loading={waitingOnStripePayment}
            type="submit"
            variant="contained"
            sx={{
              width: '100%',
              borderRadius: 2,
              mt: 2,
            }}
          >
            Recharge Now
          </CustomButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddCreditModal;
