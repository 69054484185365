/** @jsxImportSource @emotion/react */
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  css,
  Box,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import StatusBadge from '../../../components/badge';
import ProfileCell from './ProfileCell';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes/auth-routes/routes';
import { getChannelDetails } from '../../../utils/getSetChannelDetails';
import getOrganisationAPI from '../request/getOrganisation';
import { OrgListCustomersProps } from '../../home/types';
import Loader from '../../../components/loader';
import { formatCurrency } from '../../../utils/currencyFormatter';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  organisationsSearchTermAtom,
  organisationsSortTermAtom,
  organisationsTotalOrgs,
} from '../states';
import { walletConfigAtom } from '../../wallet/state';

const OrgListTable = () => {
  const navigation = useNavigate();
  const [isApiLoading, setIsApiLoading] = useState(false);
  const orgSearchValue = useAtomValue(organisationsSearchTermAtom);
  const orgSortValue = useAtomValue(organisationsSortTermAtom);
  const userWallet = useAtomValue(walletConfigAtom);
  const setTotalOrgs = useSetAtom(organisationsTotalOrgs);

  const [orgCustomers, setOrgCustomers] = useState<
    OrgListCustomersProps[] | []
  >([]);

  useEffect(() => {
    const getSetOrgDetails = async () => {
      const channelDetails = getChannelDetails();
      if (!channelDetails?.id) {
        return;
      }
      setIsApiLoading(true);
      try {
        const response = await getOrganisationAPI(channelDetails?.id, {
          search: orgSearchValue,
          sort: {
            sortBy: orgSortValue.sortFilter,
            direction: orgSortValue.direction,
          },
        });
        if (response.customers) {
          setOrgCustomers(response.customers);
        }
        setTotalOrgs(response.totalCount);
        setIsApiLoading(false);
      } catch (e) {
        setIsApiLoading(false);
        console.log(e);
      }
    };
    getSetOrgDetails();
  }, [
    orgSearchValue,
    orgSortValue.sortFilter,
    orgSortValue.direction,
    setTotalOrgs,
  ]);

  const renderTableRows = () => {
    if (isApiLoading) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={7}>
              <Box
                css={css`
                  height: 300px;
                  width: 100%;
                `}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={'8px'}
              >
                <Loader />
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
    if (Array.isArray(orgCustomers) && orgCustomers.length) {
      return (
        <TableBody>
          {orgCustomers.map((orgCustomer) => (
            <TableRow
              key={orgCustomer.id}
              onClick={() =>
                navigation(`${ROUTES.orgProfilePage}?orgId=${orgCustomer.id}`)
              }
              sx={{
                cursor: 'pointer',
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell component="th" scope="row">
                <ProfileCell maxLetters={15} name={orgCustomer.name} />
              </TableCell>
              <TableCell>{orgCustomer.id}</TableCell>
              <TableCell>
                <StatusBadge type={orgCustomer.status} />
              </TableCell>
              <TableCell>{orgCustomer.memberCount}</TableCell>
              <TableCell>{orgCustomer.wabaCount}</TableCell>
              <TableCell>
                {formatCurrency(
                  orgCustomer.credits ?? 0,
                  orgCustomer.currencyCode ?? userWallet?.data?.currencyCode
                )}
              </TableCell>
              <TableCell>{orgCustomer.subscriptionEndsOn ?? '-'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      );
    }
    const emptyTableInfo = !orgSearchValue
      ? 'No organisation added yet'
      : 'No organisation found';
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={7}>
            <Box
              css={css`
                height: 300px;
                margin: auto;
              `}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={'8px'}
            >
              <Typography
                css={css`
                  color: rgba(0, 0, 0, 0.87);
                  font-size: 14px;
                  font-weight: 400;
                `}
              >
                {emptyTableInfo}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  return (
    <TableContainer
      component={Paper}
      css={css`
        border-radius: 12px;
      `}
    >
      <Table sx={{ minWidth: 650 }} aria-label="org table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Org ID</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Members</TableCell>
            <TableCell>WABA</TableCell>
            <TableCell>Credits</TableCell>
            <TableCell>Subscription ends on</TableCell>
          </TableRow>
        </TableHead>
        {renderTableRows()}
      </Table>
    </TableContainer>
  );
};

export default OrgListTable;
