/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box, Button, Card, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import React from 'react';
import { orgProfileAtom } from '../../states';

const SettingsBody = () => {
  const profileState = useAtomValue(orgProfileAtom);

  const handleOnStopClick = () => {
    let encodedMessage = encodeURIComponent(
      'I want to Stop the subscription for ' + profileState?.data?.org?.id
    );
    window.open(`https://wa.me/918356849474?text=${encodedMessage}`, '_blank');
  };

  const handleOnDeleteClick = () => {
    let encodedMessage = encodeURIComponent(
      'I want to Delete the ' + profileState?.data?.org?.id
    );
    window.open(`https://wa.me/918356849474?text=${encodedMessage}`, '_blank');
  };

  return (
    <Box display={'flex'} gap={'20px'} flexDirection={'column'}>
      <Card>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box>
            <Typography
              css={css`
                color: #2d2d2d;
                text-align: left;
                font-size: 16px;
                font-weight: 600;
              `}
            >
              Stop subscription
            </Typography>
            <Typography
              css={css`
                color: #7a7a7a;
                text-align: left;
                font-size: 14px;
                font-weight: 500;
                margin-top: 4px;
              `}
            >
              This action is permanent and irreversible
            </Typography>
          </Box>
          <Button
            onClick={handleOnStopClick}
            css={css`
              border-radius: 8px;
              border: 1px solid #e0e0e9;
              background: #fbfbfc;
              color: #2d2d2d;
              padding: 4px 16px;
            `}
          >
            Stop subscription
          </Button>
        </Box>
      </Card>
      <Card>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box>
            <Typography
              css={css`
                color: #2d2d2d;
                text-align: left;
                font-size: 16px;
                font-weight: 600;
              `}
            >
              Delete organisation
            </Typography>
            <Typography
              css={css`
                color: #7a7a7a;
                text-align: left;
                font-size: 14px;
                font-weight: 500;
                margin-top: 4px;
              `}
            >
              This action is permanent and irreversible
            </Typography>
          </Box>
          <Button
            onClick={handleOnDeleteClick}
            color="error"
            variant="contained"
            css={css`
              border: 0px;
              padding: 4px 16px;
            `}
          >
            Delete organisation
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default SettingsBody;
