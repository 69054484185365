import requestConfig from '../../../config/request';
import { getMinorVersion } from '../../utils/versioning';
import { getFirebaseToken } from '../auth/firebase-auth';
import APIError, { APIErrorData } from './errors/api-error';
import captureError from './errors/capture-error';
import NetworkError from './errors/network-error';

export enum Methods {
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}
const clientIdentifierQueryParam = 'client=WEB';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export enum RequestURLType {
  CRM_MINI = 'CRM_MINI',
  BOT_BUILDER = 'BOT_BUILDER',
}

async function parseResponseToJson<T>(response: Response) {
  try {
    const result = (await response.json()) as T;
    return result;
  } catch (parseToJsonError) {
    throw new Error('Failed to parse json response');
  }
}

export async function makeRequest<T>(
  url: string,
  options?: RequestInit & { useDefaultHeaders?: boolean },
  isAuthRequired: boolean = false,
  isPublicApi: boolean = false,
  hasFullUrl?: boolean,
  ignoreDefaultHeaders?: boolean,
  ignoreSentry?: boolean,
  signal?: AbortSignal
): Promise<T> {
  // init the token as a string or undefined
  let token: string | undefined;

  // if the flag isAuthRequired is true
  if (isAuthRequired) {
    // get the auth token from firebase and assign the same to token
    token = await getFirebaseToken();
  }
  const {
    method = Methods.GET,
    body,
    headers,
    useDefaultHeaders = true,
  } = options ?? {};
  let path = url;
  const version = await getMinorVersion();

  const [, search] = url.split('?');

  if (search) {
    path += `&${clientIdentifierQueryParam}&version=${version}`;
  } else {
    path += `?${clientIdentifierQueryParam}&version=${version}`;
  }

  const fullUrl: string = hasFullUrl ? path : `${requestConfig.baseUrl}${path}`;
  let response: Response;
  try {
    response = await fetch(`${fullUrl}`, {
      ...options,
      method,
      body,
      headers: {
        ...(useDefaultHeaders ? defaultHeaders : {}),
        ...headers,
        ...(token && { Authorization: token }),
      },
      signal,
    });
  } catch (requestError) {
    captureError(requestError as Error);
    throw new NetworkError(requestError as Error);
  }

  // if (response.status === 401 && retry) {
  //   await getFirebaseTokenFromPlatform();
  //   return makeRequest(url, options, isAuthRequired, requestUrlType, false);
  // }

  if (!response.ok) {
    const errorResult = await parseResponseToJson<APIErrorData>(response);
    const error = new APIError(errorResult);
    const ignorableErrors = [
      'failed to fetch',
      'too many requests',
      'rate limit exceeded',
      'forbidden resource',
      'code expired or not found',
      'whatsapp integration is not accessible',
      'user does not belong to the organization',
      'user does not have permission to perform this action',
      'file size is large than maximum allowed limit',
      'integration not found',
      'integration does not exist',
      'integration is not connected',
      'integration not found or not connected',
    ];
    const hasIgnorableError =
      ignorableErrors.findIndex((ignorableError) =>
        error.error?.toLowerCase?.()?.includes?.(ignorableError)
      ) > -1 ||
      ignorableErrors.findIndex((ignorableError) =>
        error.message?.toLowerCase?.()?.includes?.(ignorableError)
      ) > -1;
    if (!ignoreSentry && !hasIgnorableError) {
      captureError(error);
    }
    throw new APIError(errorResult);
  }

  return parseResponseToJson<T>(response);
}

export async function get<T>(
  url: string,
  options?: RequestInit & { useDefaultHeaders?: boolean },
  isAuthRequired?: boolean,
  isPublicApi?: boolean,
  hasFullUrl?: boolean,
  ignoreDefaultHeaders?: boolean,
  ignoreSentry?: boolean,
  signal?: AbortSignal
): Promise<T> {
  return makeRequest<T>(
    url,
    {
      ...options,
      method: Methods.GET,
    },
    isAuthRequired,
    isPublicApi,
    hasFullUrl,
    ignoreDefaultHeaders,
    ignoreSentry,
    signal
  );
}

export async function post<T>(
  url: string,
  options?: RequestInit & { useDefaultHeaders?: boolean },
  isAuthRequired?: boolean,
  isPublicApi?: boolean,
  hasFullUrl?: boolean,
  ignoreDefaultHeaders?: boolean,
  ignoreSentry?: boolean,
  signal?: AbortSignal
) {
  return makeRequest<T>(
    url,
    {
      ...options,
      method: Methods.POST,
    },
    isAuthRequired,
    isPublicApi,
    hasFullUrl,
    ignoreDefaultHeaders,
    ignoreSentry,
    signal
  );
}
export async function put<T>(
  url: string,
  options?: RequestInit & { useDefaultHeaders?: boolean },
  isAuthRequired?: boolean,
  isPublicApi?: boolean,
  hasFullUrl?: boolean,
  ignoreDefaultHeaders?: boolean,
  ignoreSentry?: boolean
): Promise<T> {
  return makeRequest<T>(
    url,
    {
      ...options,
      method: Methods.PUT,
    },
    isAuthRequired,
    isPublicApi,
    hasFullUrl,
    ignoreDefaultHeaders,
    ignoreSentry
  );
}
