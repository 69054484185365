import { createRequestAtom } from '../../../utils/atoms/request-atom';
import { ProflieAPIResponseType } from '../types';

export const orgProfileAtom = createRequestAtom<ProflieAPIResponseType>({
  initialLoading: false,
  data: {
    subscribedPlan: {
      canMakeNextPayment: false,
      displayName: '',
      numUsers: 0,
      renewalDate: '',
    },
    org: {
      id: '',
      dateCreated: '',
      dateUpdated: '',
      name: '',
      domain: '',
      ownerId: '',
      pictureUrl: '',
      phone: '',
      description: '',
      businessTimings: '',
      countryCode: '',
      state: '',
      city: '',
      email: '',
      website: '',
      website2: '',
      category: '',
      balanceCredits: '',
      balanceAmount: '',
      walletCurrency: '',
      billingAddress: '',
      taxType: '',
      language: '',
      planId: '',
    },
    waba: {
      totalWabaCount: 0,
    },
    wallet: {
      balance: 0,
      currencyCode: '',
    },
    customerLoginUrl: '',
  },
});
