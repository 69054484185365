import { get, post } from '../../../infra/rest';
import { getChannelDetails } from '../../../utils/getSetChannelDetails';
import { ProflieAPIResponseType, WabaListAPIResponseType } from '../types';

export const getProflieAPI = async ({
  orgId,
}: {
  orgId: string;
}): Promise<ProflieAPIResponseType> => {
  const partnerId = getChannelDetails()?.id;
  if (!partnerId) {
    throw new Error('Invalid partner ID');
  }
  return get<ProflieAPIResponseType>(
    `/v1/channel-partner/${partnerId}/customer/${orgId}/details`,
    {
      useDefaultHeaders: false,
    },
    true
  );
};

export const getProflieWabaListAPI = async ({
  orgId,
}: {
  orgId: string;
}): Promise<WabaListAPIResponseType> => {
  const partnerId = getChannelDetails()?.id;
  if (!partnerId) {
    throw new Error('Invalid partner ID');
  }
  return post<WabaListAPIResponseType>(
    `/v1/channel-partner/${partnerId}/customer/${orgId}/waba/list`,
    {
      useDefaultHeaders: false,
    },
    true
  );
};
