const useRazorPayPaymentHook = () => {
  const makeRazPayment = ({
    apiKey,
    orderId,
    amount,
    successCallback,
  }: {
    apiKey: string;
    orderId: string;
    amount: number;
    successCallback?: (responseId: string) => void;
  }) => {
    const razorpayOption = {
      key: apiKey,
      amount: amount, // Amount in paise (e.g. 50000 paise = ₹500)
      currency: 'INR',
      name: 'DoubleTick',
      description: 'Payment for DoubleTick',
      order_id: orderId,
      image: 'https://doubletick.io/assets/favicons/favicon.svg',
      handler: function (response: any) {
        // Handle the payment success
        if (response.razorpay_payment_id) {
          successCallback?.(response.razorpay_payment_id);
        }
        console.log(response);
      },
      prefill: {
        // name: 'John Doe',
        // email: 'john.doe@example.com',
        // contact: '9876543210',
      },
      notes: {
        // address: 'DoubleTick',
      },
      theme: {
        color: '#5DB779',
      },
    };
    const rzp = new window.Razorpay(razorpayOption);

    rzp.open!();
  };

  return { makeRazPayment };
};
export default useRazorPayPaymentHook;
