/** @jsxImportSource @emotion/react */
import React, { useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Divider, Grid, TextField } from '@mui/material';
import ProfileCell from '../../organisations/components/ProfileCell';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import WalletCard from '../../../components/card/WalletCard';
import { CreditCheckoutProps } from '../types';
import { rechargeCustomerWallet } from '../request';
import useNotifications from '../../../hooks/useNotifications';
import CustomButton from '../../../components/button/CustomButton';
import { formatCurrency } from '../../../utils/currencyFormatter';
import { useAtomValue } from 'jotai';
import { walletConfigAtom } from '../state';
import { calculateGSTInclusivePrice } from '../../../utils/currency';

const CreditCheckout = ({
  setCurrentStep,
  selectedOrg,
  handleClose,
}: CreditCheckoutProps) => {
  const [creditAmount, setCreditAmount] = useState<number>(0);
  const [isAPILoading, setisAPILoading] = useState<boolean>(false);
  const walletConfig = useAtomValue(walletConfigAtom);
  const GST_Value = calculateGSTInclusivePrice(creditAmount);

  const { addNotification } = useNotifications();

  const handleSubmit = async () => {
    try {
      if (!creditAmount) {
        addNotification({
          message: 'Please enter a valid amount',
          type: 'error',
        });
        return;
      }
      setisAPILoading(true);
      const response = await rechargeCustomerWallet({
        credits: creditAmount,
        orgId: selectedOrg?.id,
      });
      if (response?.success) {
        addNotification({
          message: 'Credits assigned successfully',
          type: 'success',
        });
        handleClose();
      } else {
        addNotification({
          message: 'Credits assigned failed',
          type: 'error',
        });
      }
      setisAPILoading(false);
    } catch (e) {
      setisAPILoading(false);

      addNotification({
        message: (e as Error).message || 'Something went wrong',
        type: 'error',
      });
      console.log(e);
    }
  };
  const getTotalWalletAmount = () => {
    if (walletConfig.loading) {
      return 0;
    }
    if (!walletConfig.data) {
      return 0;
    }
    return walletConfig.data.balance;
  };

  const totalNumberOfCredits = useMemo(() => {
    if (isNaN(creditAmount)) {
      return selectedOrg.credits ?? 0;
    }
    return (selectedOrg.credits ?? 0) + (creditAmount ?? 0) - GST_Value;
  }, [selectedOrg.credits, creditAmount, GST_Value]);

  if (!selectedOrg?.id) {
    return <>No org selected</>;
  }

  const hasInSufficientBalance = totalNumberOfCredits > getTotalWalletAmount();
  const renderGST = () => {
    if (walletConfig?.data?.currencyCode === 'INR') {
      return (
        <Grid container gap={'12px'} alignItems="center">
          <Grid item xs={2.5}>
            <Typography
              sx={{
                color: '#2d2d2d',
                fontSize: 14,
              }}
            >
              GST (18%)
            </Typography>
          </Grid>
          <Grid item xs />
          <Grid item xs={'auto'}>
            <Typography
              sx={{
                color: '#2d2d2d',
                fontWeight: 600,
                fontSize: 14,
              }}
            >
              {formatCurrency(GST_Value, selectedOrg.currencyCode ?? 'INR')}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return null;
  };
  return (
    <div>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <WalletCard />
        <Grid container>
          <Grid item xs={6}>
            <ProfileCell
              name={selectedOrg.name}
              secondaryText={formatCurrency(
                selectedOrg.credits ?? 0,
                selectedOrg.currencyCode ??
                  walletConfig?.data?.currencyCode ??
                  'INR'
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              label="Enter amount"
              fullWidth
              type="number"
              error={hasInSufficientBalance}
              helperText={
                hasInSufficientBalance
                  ? 'Amount exceeds available wallet balance'
                  : ''
              }
              onChange={(e) => setCreditAmount(parseFloat(e.target.value))}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            bgcolor: '#E0E0E9',
            borderRadius: 4,
            padding: 1.5,
          }}
        >
          <Typography
            sx={{
              color: '#585858',
              fontSize: 12,
              fontWeight: 400,
            }}
          >
            Your credits will be instantly debited
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        {renderGST()}
        <Grid container gap={'12px'} alignItems="center">
          <Grid item xs={2.5}>
            <Typography
              sx={{
                color: '#2d2d2d',
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              Total Balance
            </Typography>
          </Grid>
          <Grid item xs />
          <Grid item xs={'auto'}>
            <Typography
              sx={{
                color: '#2d2d2d',
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              {formatCurrency(
                totalNumberOfCredits,
                selectedOrg.currencyCode ?? 'INR'
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container gap={'12px'} alignItems="center">
          <Grid item xs={2.5}>
            <Button
              onClick={() => setCurrentStep('select_organisation')}
              variant="outlined"
              sx={{
                width: '100%',
                borderRadius: 2,
              }}
            >
              Go Back
            </Button>
          </Grid>
          <Grid item xs />
          <Grid item xs={'auto'}>
            <CustomButton
              disabled={isAPILoading || hasInSufficientBalance}
              loading={isAPILoading}
              onClick={handleSubmit}
              variant="contained"
              sx={{
                width: '100%',
                borderRadius: 2,
              }}
            >
              Assign Credits
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CreditCheckout;
