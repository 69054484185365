import { get, post } from '../../../infra/rest';
import {
  ClientWalletRechargeAPIResponse,
  PaymentHistoryRowType,
  WalletConfigType,
  WalletRechargeType,
} from '../types';
import { getChannelDetails } from '../../../utils/getSetChannelDetails';

export const getWalletConfig = async (): Promise<WalletConfigType> => {
  const channelDetails = getChannelDetails();
  if (!channelDetails?.id) {
    throw new Error('Channel details not found');
  }
  const url = `/v1/channel-partner/${channelDetails.id}/wallet`;
  return get<WalletConfigType>(
    url,
    {
      useDefaultHeaders: false,
    },
    true,
    false,
    false,
    true,
    true
  );
};

export const getPaymentHistory = async (): Promise<PaymentHistoryRowType[]> => {
  const channelDetails = getChannelDetails();
  if (!channelDetails?.id) {
    throw new Error('Channel details not found');
  }
  const url = `/v1/channel-partner/${channelDetails.id}/wallet/logs`;
  return get<PaymentHistoryRowType[]>(
    url,
    {
      useDefaultHeaders: false,
    },
    true,
    false,
    false,
    true,
    true
  );
};

export const rechargeWallet = async ({
  amount,
}: {
  amount: number;
}): Promise<WalletRechargeType> => {
  const channelDetails = getChannelDetails();
  if (!channelDetails?.id) {
    throw new Error('Channel details not found');
  }
  const url = `/v1/channel-partner/${channelDetails.id}/wallet`;
  return post<WalletRechargeType>(
    url,
    {
      body: JSON.stringify({ amount }),
      useDefaultHeaders: false,
      headers: {
        'Content-Type': 'application/json',
      },
    },
    true,
    false,
    false,
    true,
    true
  );
};

export const rechargeCustomerWallet = async ({
  credits,
  orgId,
}: {
  credits: number;
  orgId: string;
}): Promise<ClientWalletRechargeAPIResponse> => {
  const channelDetails = getChannelDetails();
  if (!channelDetails?.id) {
    throw new Error('Channel details not found');
  }
  const url = `/v1/channel-partner/${channelDetails.id}/customer/${orgId}/wallet/credits`;
  return post<ClientWalletRechargeAPIResponse>(
    url,
    {
      body: JSON.stringify({ credits }),
      useDefaultHeaders: false,
      headers: {
        'Content-Type': 'application/json',
      },
    },
    true,
    false,
    false,
    true,
    true
  );
};
