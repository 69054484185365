/** @jsxImportSource @emotion/react */
import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { css } from '@emotion/react';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
// import StatusBadge, { StatusBadgeTypes } from '../../../../components/badge';
import TwoHumanIcon from '../../../../../icons/TwoHumanIcon';
import { WabaListAPIResponseItemType } from '../../types';

const OrganizationCard: React.FC<WabaListAPIResponseItemType> = ({
  wabaPhoneName,
  wabaNumber,
  // status,
  dateAdded,
  assignedAgentCount,
}) => (
  <Stack
    direction={'row'}
    gap={'16px'}
    css={css`
      border-bottom: 1px solid #e0e0e9;
      padding: 20px 0;
    `}
  >
    {/* {logoUrl ? (
      <Box
        css={css`
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #e0e0e9;
          border-radius: 8px;
          padding: 9px;
        `}
      >
        <img
          css={css`
            height: 100%;
            width: 100%;
          `}
          src={logoUrl}
          alt="logo"
        />
      </Box>
    ) : null} */}
    <Box>
      <Stack direction={'row'} gap={'8px'}>
        <Typography
          css={css`
            color: #2d2d2d;
            font-size: 16px;
            font-weight: 600;
          `}
        >
          {wabaPhoneName}
        </Typography>
        <Typography
          css={css`
            color: #585858;
            font-size: 16px;
            font-weight: 500;
          `}
        >
          {wabaNumber}
        </Typography>
        {/* <StatusBadge type={status} /> */}
      </Stack>

      <Stack direction={'row'} alignItems={'center'} mt={'7px'}>
        <Stack direction={'row'} gap={'4px'}>
          {dateAdded ? (
            <>
              <CalendarTodayOutlinedIcon
                sx={{ height: '16px', width: '14px' }}
              />
              <Typography
                css={css`
                  color: #585858;
                  font-size: 12px;
                  font-weight: 400;
                `}
              >
                Added on{' '}
                <Typography
                  css={css`
                    color: #2d2d2d;
                    font-size: 12px;
                    font-weight: 400;
                    display: inline;
                  `}
                >
                  {dateAdded}
                </Typography>
              </Typography>
            </>
          ) : null}
        </Stack>

        <Stack direction={'row'} gap={'4px'} alignItems={'center'}>
          <TwoHumanIcon />
          <Typography
            css={css`
              color: #585858;
              font-size: 12px;
              font-weight: 400;
            `}
          >
            Assigned to{' '}
            <Typography
              css={css`
                color: #2d2d2d;
                font-size: 12px;
                font-weight: 400;
                display: inline;
              `}
            >
              {assignedAgentCount}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  </Stack>
);

export default OrganizationCard;
